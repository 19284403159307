import React from 'react'
import tw, { styled } from 'twin.macro'
import { motion, AnimatePresence } from 'framer-motion'
import Chevron from '@elements/Chevron'
import { useInView } from 'react-intersection-observer'
import { useStaticQuery, graphql } from 'gatsby'
import { strings } from '../../static/strings'
import { useBreakpoint } from '@hooks/useBreakpoint'

const WrapBox = tw(motion.div)`relative mx-auto md:absolute left-0 bottom-0 w-full  md:w-1/3 lg:w-4/12 2xl:w-3/12 z-30`
const Box = tw(motion.div)`bg-greyStripe pt-spacingS-xs pb-spacingXs-xs px-offset-xs`
const Content = tw.div`lg:px-5 2xl:px-0 my-8 w-full max-w-screen-xl mx-auto`
const Headline = tw.p`uppercase font-futuraBold text-3xl xl:text-5xl text-white mb-8`
const TextWrap = styled(motion.div)`
	${tw`relative w-full pr-2 my-2 mx-auto overflow-hidden h-[420px] md:h-[300px] xl:h-[420px] overflow-y-auto`}

	::-webkit-scrollbar {
		width: 6px;
	}
	::-webkit-scrollbar-track {
		background: #3c3c3c;
	}
	::-webkit-scrollbar-thumb {
		background: #cccccc;
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #f5f5f5;
	}
`
const StandingItem = tw(motion.div)`relative text-white px-3 py-3 h-auto w-full mx-auto overflow-hidden before:absolute before:w-full before:bottom-0 before:left-0 before:h-px before:bg-white`
const Overline = tw(motion.p)`text-sm tracking-1`
const Name = styled(motion.p)`
	${tw`py-1.5 text-sm text-white xl:text-copyXl tracking-1 md:tracking-2`}
`
const CarName = tw(motion.p)`relative h-auto w-full mx-auto overflow-hidden text-sm tracking-1`
const Flex = tw.div`px-5 flex items-center justify-center gap-4`
const ShowMore = tw(motion.div)`hidden md:block uppercase relative mx-5 xl:mx-0 pt-2 mt-8 text-copyXl cursor-pointer text-mediumDark tracking-1 md:tracking-2`

const StandingsNav = tw.div`grid grid-cols-2`
const SerieTab = styled.button`
	${tw`px-2 py-3 text-sm uppercase transition-colors border-b-2 border-b-mediumDark tracking-1 md:tracking-2 text-mediumDark`}

	&.active {
		${tw`text-white border-b-white`}
	}
`

const Standings = ({ className, lang, sheetData, loading }) => {
	const { isMobile } = useBreakpoint()
	const data = useStaticQuery(graphql`
		{
			wp {
				acfOptionsStandings {
					optionsStandings {
						serie1Name
						serie1Teams {
							name
							car
							points
						}
						serie2Name
						serie2Teams {
							name
							car
							points
						}
					}
				}
			}
		}
	`)
	const standings = data.wp.acfOptionsStandings?.optionsStandings

	const tableRef = React.useRef([])
	const [activeTab, setActiveTab] = React.useState('serie_1')
	const [chevronPosition, setChevronPosition] = React.useState('down')
	const serieStandings = [
		{ name: 'gt-world-challenge-europe.com', url: 'https://www.gt-world-challenge-europe.com/standings?filter_standing_type=0_0_teams' },
		{ name: 'dtm.com', url: 'https://www.dtm.com/de/wertung' },
	]

	return (
		<WrapBox className={className}>
			<Box>
				<motion.div className='wrap'>
					<Content>
						<Headline>{strings[lang].standings}</Headline>
						<StandingsNav>
							<SerieTab
								onClick={() => {
									setChevronPosition('down')
									setActiveTab('serie_1')
								}}
								className={`${activeTab === 'serie_1' && 'active'}`}
							>
								{standings.serie1Name}
							</SerieTab>
							<SerieTab
								onClick={() => {
									setChevronPosition('down')
									setActiveTab('serie_2')
								}}
								className={`${activeTab === 'serie_2' && 'active'}`}
							>
								{standings.serie2Name}
							</SerieTab>
						</StandingsNav>
						{!loading && (
							<TextWrap>
								<div className='p-3 text-xl text-white' key='loading-state'>
									{strings[lang].loading}...
								</div>
							</TextWrap>
						)}
						{loading &&
							[...Array(2)].map((serie, i) => {
								const module = i === 0 ? standings.serie1Teams : standings.serie2Teams

								return (
									activeTab === `serie_${i + 1}` && (
										<AnimatePresence key={`wraptable-${i}`}>
											<TextWrap ref={(tRef) => (tableRef.current[i] = tRef)} initial={{ x: -30, opacity: 0 }} animate={{ x: 0, opacity: 1 }}>
												{i === 0 &&
													sheetData.length > 0 &&
													sheetData[1].data.map((item, index) => {
														if (index === 0) return
														const value = item[sheetData[1].data.length]
														let valueArray = value.split(/([0-9]+.?[0-9]?)/).filter((entry) => /\S/.test(entry))
														let teamName = valueArray[1]
														let points = valueArray[2].split(/[ ]+/)[0]
														valueArray.filter((val, i) => {
															let __val = val.replace(/\s+$/, '')
															if (/^\d+$/.test(__val)) {
																if (!/^\d+$/.test(valueArray?.[i - 1]) && valueArray?.[i - 1] !== undefined && !/^\d+$/.test(valueArray?.[i + 1]) && valueArray?.[i + 1] !== undefined) {
																	teamName = valueArray?.[i - 1].concat(val, valueArray?.[i + 1])
																	points = valueArray?.[i + 2].split(/[ ]+/)[0]
																}
															}
														})

														const teamData = {
															lp: parseInt(valueArray[0]),
															name: teamName,
															points: points,
														}

														return (
															<StandingItem key={`standings-${index}`}>
																<Overline>
																	{teamData.lp}
																	{['st', 'nd', 'rd'][(((((teamData.lp < 0 ? -teamData.lp : teamData.lp) + 90) % 100) - 10) % 10) - 1] || 'th'}
																</Overline>
																<Name className={teamData.name.toLowerCase().includes('emil frey racing') ? 'font-futuraBold' : ''}>
																	{teamData.name} ({teamData.points})
																</Name>
																{/* <CarName>{item.car}</CarName> */}
															</StandingItem>
														)
													})}
												{i === 1 &&
													sheetData.length > 0 &&
													sheetData[0].data.map((item, index) => {
														const value = item[sheetData[0].data.length]
														const teamData = {
															lp: parseInt(value.split(',')[0]),
															name: value.split(',')[1],
															points: value.split(',')[2],
														}

														return (
															<StandingItem key={`standings-${index}`}>
																<Overline>
																	{teamData.lp}
																	{['st', 'nd', 'rd'][(((((teamData.lp < 0 ? -teamData.lp : teamData.lp) + 90) % 100) - 10) % 10) - 1] || 'th'}
																</Overline>
																<Name className={teamData.name.toLowerCase().includes('emil frey racing') ? 'font-futuraBold' : ''}>
																	{teamData.name} ({teamData.points.trim(' ')})
																</Name>
															</StandingItem>
														)
													})}
												{/* [...Array(3)].map((item, index) => {
														return (
															<StandingItem key={`standings-${index}`}>
																<Overline>
																	{index + 1}
																	{['st', 'nd', 'rd'][(((((index + 1 < 0 ? -(index + 1) : index + 1) + 90) % 100) - 10) % 10) - 1] || 'th'}
																</Overline>
																<Name>-- ()</Name>
															</StandingItem>
														)
													})} */}
												<StandingItem>
													<Name className='uppercase'>{strings[lang].allStandings}</Name>
													<a className='relative w-full h-auto mx-auto overflow-hidden text-sm tracking-1 hover:text-black' href={serieStandings[i].url} target='_blank' rel='noopener noreferrer'>
														{serieStandings[i].name}
													</a>
												</StandingItem>
											</TextWrap>
										</AnimatePresence>
									)
								)
							})}
						<ShowMore
							onClick={() => {
								const currTabWrap = tableRef.current[activeTab === `serie_1` ? 0 : 1]
								currTabWrap.scroll({
									top: chevronPosition === 'down' ? currTabWrap.scrollTop + 100 : currTabWrap.scrollTop - 100,
									left: 0,
									behavior: 'smooth',
								})
								if (currTabWrap.scrollHeight - currTabWrap.scrollTop === currTabWrap.getBoundingClientRect().height) setChevronPosition('up')
								else setChevronPosition('down')
							}}
						>
							<Flex>
								<Chevron fill='#fff' size='big' state={chevronPosition} />
							</Flex>
						</ShowMore>
					</Content>
				</motion.div>
			</Box>
		</WrapBox>
	)
}
export default Standings
